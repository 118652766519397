$primary: #eb5c27;
$info  : #d9edf7;
$success : #dff0d8;

@import "bootstrap";

@font-face {
    font-family: 'bg_bold';
    src: url('/assets/fonts/brandongrotesque-bold-webfont.eot');
    src: url('/assets/fonts/brandongrotesque-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brandongrotesque-bold-webfont.woff2') format('woff2'),
    url('/assets/fonts/brandongrotesque-bold-webfont.woff') format('woff'),
    url('/assets/fonts/brandongrotesque-bold-webfont.ttf') format('truetype'),
    url('/assets/fonts/brandongrotesque-bold-webfont.svg#brandon_grotesquebold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bg_light';
    src: url('/assets/fonts/brandongrotesque-light-webfont.eot');
    src: url('/assets/fonts/brandongrotesque-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brandongrotesque-light-webfont.woff2') format('woff2'),
    url('/assets/fonts/brandongrotesque-light-webfont.woff') format('woff'),
    url('/assets/fonts/brandongrotesque-light-webfont.ttf') format('truetype'),
    url('/assets/fonts/brandongrotesque-light-webfont.svg#brandon_grotesquelight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bg_med';
    src: url('/assets/fonts/brandongrotesque-medium-webfont.eot');
    src: url('/assets/fonts/brandongrotesque-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brandongrotesque-medium-webfont.woff2') format('woff2'),
    url('/assets/fonts/brandongrotesque-medium-webfont.woff') format('woff'),
    url('/assets/fonts/brandongrotesque-medium-webfont.ttf') format('truetype'),
    url('/assets/fonts/brandongrotesque-medium-webfont.svg#brandon_grotesquemedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bg_reg';
    src: url('/assets/fonts/brandongrotesque-regular-webfont.eot');
    src: url('/assets/fonts/brandongrotesque-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brandongrotesque-regular-webfont.woff2') format('woff2'),
    url('/assets/fonts/brandongrotesque-regular-webfont.woff') format('woff'),
    url('/assets/fonts/brandongrotesque-regular-webfont.ttf') format('truetype'),
    url('/assets/fonts/brandongrotesque-regular-webfont.svg#brandon_grotesque_regularRg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bg_thin';
    src: url('/assets/fonts/brandongrotesque-thin-webfont.eot');
    src: url('/assets/fonts/brandongrotesque-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brandongrotesque-thin-webfont.woff2') format('woff2'),
    url('/assets/fonts/brandongrotesque-thin-webfont.woff') format('woff'),
    url('/assets/fonts/brandongrotesque-thin-webfont.ttf') format('truetype'),
    url('/assets/fonts/brandongrotesque-thin-webfont.svg#brandon_grotesquethin') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: "bg_reg", sans-serif;
    font-size: 14px;
}

main {
    padding:75px 0;
}

code {
    border:1px solid #ddd;
    display: inline-block;
    margin-right: 20px;
    padding:0 5px;
}

.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-info  {
    color:white;
    border-color: #269abc;
    background-color: #5bc0de;
}

h5 {
    border-bottom: 1px solid #aaa;
}

@keyframes fadeIn {
    0% { opacity: 0}
    100% { opacity: 1}
}

header {
    .header-title {
        display: flex;
        align-items: center;
        color:white;
        font-size: 1.2em;
        height:45px;

        code {
            font-size: 0.6rem;
            border:1px solid #ddd;
            background-color: $secondary;
            display: block;
            margin-right: 20px;
            color:white;
        }
    }
}

.mb-50 {
    margin-bottom: 50px;
}

.no-border {
    border-width: 0;
}

.faded {
    opacity: 0.5;
}

.padded {
    padding-left: 20px;
    padding-right: 20px;
}

.flexed-end {
    display: flex;
    justify-content: flex-end;
}

.flexed-between {
    display: flex;
    justify-content: space-between;
}

.flexed-around {
    display: flex;
    justify-content: space-around;
}

.flexed-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.abs-bottom-right {
    position: absolute;
    bottom:30px;
    right:30px;
}

.font-size-sm {
    font-size: 0.9em;
}

.font-size-xs {
    font-size: 0.8em;
}

.font-size-lg {
    font-size: 1.1em;
}

.font-size-xl {
    font-size: 1.1em;
}

.signin {
    background-image: url('/assets/img/background.png');
    min-height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;

    .error-section {
        margin-top:20px;
        height:75px;
    }

    .logo-section {
        text-align: center;

        img {
            width: 100%;
        }
    }

    div {
        div {
            width:60vw;
        }
    }
}

.splash {
    background-image: url('/assets/img/background.png');
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    animation-name : fadeIn;
    animation-duration : 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    img {
        width: 60%;
    }
}

.btn-ergo {
    border:1px solid white;
    height:30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.navbar-collapse {
    background-color: $primary;
    color:white;
    position: fixed;
    height: calc(100vh - 58px);
    overflow: auto;
    width: 60vw;
    left:100vw;
    transition: 0.25s all;
    margin-top:58px;
    z-index: 11;

    ul {
        display: flex !important;
        min-height:100%;
        flex-direction: column;

        li {
            padding:5px 10px;

            & > div {
                color:white;
                position: relative;
                display: inline-block;
                margin-left:40px;
                width: calc(100% - 40px);
            }

            span {
                position: absolute;
                left:-40px;
                top: 8px;
                width:40px;
                text-align: center;
            }

            &.active {
                background-color:lighten($primary, 20%);
            }

            &:hover {
                background-color:lighten($primary, 20%);
            }

            &:hover:first-child {
                background-color:transparent;
            }

            &:last-child {
                margin-top: auto;
            }
        }
    }
}

.gray-overlay {
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    min-height:100vh;
    background-color:rgba(0, 0, 0, 0.25);
    animation-name : fadeIn;
    animation-duration : 0.25s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 10;
}

.navbar-visible {
    left:40vw;
}

.flexed {
    display: flex;
    flex-grow: 1;
}

.flexed-justify-end {
    justify-content: flex-end;
}

.flexed-justify-center {
    justify-content: center;

    & > * {
        flex-grow: 0 !important;
    }
}

.align-self-left {
    margin-right:auto;
}

.modal {
    background-color:rgba(0, 0, 0, 0.25);

    .modal-body {
        max-height: 70vh;
        overflow: auto;
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;
    }
}

.section-collapsed {
    height:0;
    position: relative;
    overflow: hidden;
    transition: height 0.3s;
}

.section-expanded {
    height:50px;
}

form {
    h4 {
        padding-top:10px;

        &:first-child {
            border-top:0px solid white;
        }
    }

    .form-row {
        margin-bottom:30px;

        label {
            position: relative;
            top:10px;
        }

        & > div {
            margin-bottom:10px;
        }

        &.no-margin {
            margin-bottom: 0;
        }
    }

}

.flexed-full-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.full-width-no-margin {
    width: 100%;
    margin:0;
}

.chat-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 10vh;

    .chat-msg {
        margin-bottom:30px;
        position: relative;

        .msg-content {
            display: inline-block;
            color:white;
            padding:10px;
            border-radius: 3px;
            min-width: 100px;
        }

        &.unread-line::before {
            content:"";
            position: absolute;
            width: 100%;
            height:2px;
            background-color: #eee;
            top:-20px;
            left:0;
            opacity: 0;
            animation: fadeIn 1s linear 1s 1 forwards normal;
        }

        &.to-you {
            text-align: left;

            .msg-content {
                background-color: $secondary;
            }

            :last-child {
                left:0;
            }
        }

        &.by-you {
            text-align: right;

            .msg-content {
                background-color: $primary;
            }

            :last-child {
                right:0;
            }
        }

        :last-child {
            position: absolute;
            top:-18px;
            font-size: 0.7em;
            color:#888;
        }
    }

    .chat-input {
        margin-top:auto;
    }
}

.flex-grow {
    flex-grow:1;
}

.list {
    .list-item {
        border-top:1px solid #aaa;

        &:last-child {
            border-bottom:1px solid #aaa;
        }

        .item-main-line {
            background-color: #f1f1f1;

            &.available-status {
                background-color: $info;
            }

            &.affected-status,
            &.registered-status {
                background-color: $success;
            }
        }

        .location-info {
            font-weight: 600;
            display: flex;
            justify-content: space-between;

            .text-info {
                color: #269abc !important;
            }

            .text-success {
                color: #3c763d !important;
            }
        }

        .general-info {
            font-size: 0.9em;
            display: flex;
            justify-content: space-between;
        }

        .action-section {
            margin-top:5px;

            & > div > div {
                margin-bottom: 5px;
                text-align: right;

                button {
                    display: inline-block;
                    width: 40px;
                }
            }
        }

        .details-info {
            height:0;
            position: relative;
            overflow: hidden;
            transition: height 0.3s;
            display: flex;
            flex-direction: column;

            &.expanded {
                border-top:1px solid #aaa;
                height:300px;
                padding-bottom: 10px;
            }

            &.expanded-lg {
                border-top:1px solid #aaa;
                height:400px;
                padding-bottom: 10px;
                overflow: auto;
            }

            &.expanded-xl {
                border-top:1px solid #aaa;
                height:450px;
                padding-bottom: 10px;
                overflow: auto;
            }

            .flex-detail-line {
                display: flex;

                & > :first-child {
                    flex-shrink: 0;
                    flex-basis: 100px;
                }
            }

            .action-line {
                margin-top:auto;
                display: flex;
                justify-content: flex-end;
                padding:20px;

                & > div {
                    margin-left:20px;
                }
            }
        }
    }
}


.circular-range-widget {
    .circular {
        height: 120px;
        width: 120px;
        position: relative;

        .inner,
        .outer,
        .circle{
            position: absolute;
            height: 100%;
            width: 100%;
        }

        .inner {
            z-index: 1;
            top: 50%;
            left: 50%;
            height: 110px;
            width: 110px;
            margin: -55px 0 0 -55px;
            background-color: white;
            border-radius: 100%;
        }

        .text {
            position: absolute;
            top:0;
            margin-left: 10%;
            width: 80%;
            margin-top:30px;
            z-index: 1;
            font-weight: 500;
            color: #eb5c27;
            text-align: center;
            line-height: 1.4em;
            font-size: 0.9em;

            div {
                font-size: 2em;
                margin-bottom: 5px;
            }
        }

        .bar {
            position: absolute;
            height: 100%;
            width: 100%;
            clip: rect(0px, 120px, 120px, 60px);

            .progress{
                background-color:#eb5c27;
                position: absolute;
                height: 100%;
                width: 100%;
                border-radius: 100%;
                clip: rect(0px, 60px, 120px, 0px);
            }
        }
    }

    .circle{
        box-shadow: none;

        .bar .progress,
        .left .progress {
            animation: circleAnim 1s ease-in both;
        }

        .left {
            left:-0.05px;
        }

        .right{
            transform: rotate(180deg);

            .progress{
                animation: circleAnim 1s ease-out both;
                animation-delay: 1s;
            }
        }
    }
}

.dashboard-menu {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    justify-content: center;

    .nav-item {
        text-align: center;
        list-style-type: none;
        padding:15px;
        display: flex;
        justify-content: center;

        .nav-link {
            width: 15vh;
            height: 13vh;
            border-radius: 5px;
            background-color: $secondary;
            color: white;
            display: flex;
            flex-direction: column;
            padding:0;

            & > span {
                flex-basis: 50%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                font-size: 2em;

                @media only screen and (max-width: 400px) {
                    font-size: 1.6em;
                }
            }

            & > div {
                flex-basis: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                line-height: 1.2em;

                @media only screen and (max-width: 400px) {
                    font-size: 0.8em;
                    line-height: 1em;
                }
            }
        }
    }
}

footer {
    display: flex;
    background-color: #eb5c27;
    height:10vh;
    position: fixed;
    bottom:0;
    width: 100%;
    color:white;

    .footer-item {
        flex-basis: 25%;
        text-align: center;
        display: flex;
        flex-direction: column;

        & > div {
            flex:1;
            padding-top:1.5vh;
            font-size: 1.5rem;

            @media only screen and (max-width: 400px) {
                font-size: 1rem;
            }
        }

        &.active {
            background-color:rgba(255, 255, 255, 0.2);
        }

        .item-title {
            padding-top:0.5vh;
            font-size: 1.1rem;
            font-weight: 400;

            @media only screen and (max-width: 400px) {
                font-size: 0.8rem;
            }
        }
    }
}

@keyframes circleAnim {
    100%{
        transform: rotate(180deg);
    }
}